import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Map from "./Map/Map";
import { graphql, useStaticQuery } from "gatsby";
import HeadingCTA from "../HeadingCTA/HeadingCTA";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import LocationsAccordion from "./LocationsAccordion/LocationsAccordion";
import Img from "gatsby-image";
import { mobileViewBreakpoint } from "../helper";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
//
const useStyles = makeStyles((theme) => ({
  locationsWrapper: {
    height: "1040px",
    // backgroundColor: 'rgba(0, 0, 0, 0.1)',
    background:
      "linear-gradient(96.34deg, #FCFCFC 8.92%, #FCFCFC 8.93%, #F8FAFF 100%)",
    // backgroundImage: 'url(' + LocationBackground + ')',
    // background: 'black',
    // backgroundRepeat: 'no-repeat',
    // backgroundPosition: 'right center',
    // backgroundSize: 'contain',
    display: "flex",
    justifyContent: "flex-start",
    // alignItems: 'center',
    position: "relative",
    marginTop: "120px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      display: "block",
      background: "none",
      height: "auto",
    },
  },

  headingCTAWrapper: {
    marginTop: "128px",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%) translateY(0%)",
    zIndex: 99,
  },
  mobileHeadingCTAWrapper: {
    marginBottom: "54px",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      padding: "0px 16px",
    },
  },
  mapLocation: {
    position: "absolute",
    right: 0,
    bottom: 0,
    top: 0,
    width: "100%",
    height: "100%",
    transition: "opacity ease-in-out 0.2s",
    "& .gatsby-image-wrapper": {
      width: "100%",
      height: "100%",
    },
  },
  mapImgContainer: {
    position: "absolute",
    width: "51%",
    height: "100%",
    right: "0",
    "&::after": {
      content: '""',
      background:
        "linear-gradient(90deg, rgb(252 252 252) 10%, rgba(252, 252, 252,0.6) 100%)",
      height: "100%",
      width: "100%",
      position: "absolute",
      left: "0",
      zIndex: "10",
    },
    "&::before": {
      content: '""',
      background:
        "linear-gradient(180deg, rgba(250, 251, 254, 0) 87.12%, #fafbfd 98.61%)",
      height: "100%",
      width: "100%",
      position: "absolute",
      bottom: "0",
      zIndex: "10",
    },
  },
  imgNew: {
    visibility: "visible",
    opacity: "1",
  },
  imgDefault: {
    visibility: "visible",
    opacity: "1",
  },
}));

const Locations = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [locationImg, setLocationImg] = useState();
  const [isImageChange, setIsImageChange] = useState(true);
  const isMobileView = useMediaQuery(
    theme.breakpoints.down(mobileViewBreakpoint)
  );
  const data = useStaticQuery(graphql`
    query {
      DubaiImage: file(relativePath: { eq: "locations/locations_dubai.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      SouthAfricaImage: file(
        relativePath: { eq: "locations/locations_zar.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      DelawareImage: file(relativePath: { eq: "locations/locations_usa.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      SaudiArabiaImage: file(
        relativePath: { eq: "locations/locations_ksa.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      AbuDhabiImage: file(
        relativePath: { eq: "locations/locations_abudhabi.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }

      IndiaImage: file(relativePath: { eq: "locations/locations_inida.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);
  const locationsData = [
    {
      city: "UAE",
      address: (
        <>
          <b>Dubai</b>: 806 Tameem House, Tecom, Dubai
          <br />
          <br />
          <b>Abu Dhabi</b>: Office 17, Al Mariah Mall, Abu Dhabi
        </>
      ),
      phone: "+971 (0)4 874 6677",
      css: "markerDubai",
      country: "United Arab Emirates",
      countryImage: data.DubaiImage.childImageSharp.gatsbyImageData,
    },
    {
      city: "Saudi Arabia",
      address: "Building S4 Roshn Business Front, Airport Rd, Riyadh",
      phone: "+966 12 630 0001",
      css: "markerSaudiArabia",
      country: "Saudi Arabia",
      countryImage: data.SaudiArabiaImage.childImageSharp.gatsbyImageData,
      multiline: true,
      emails: [
        { name: "For project based roles:", address: "staffing@motusone.com" },
        {
          name: "To join our vendor list:",
          address: "procurement@motusone.com",
        },
        {
          name: "For suggestions and complaints:",
          address: "suggestions@motusone.com",
        },
      ],
    },
    {
      city: "USA",
      address: `1209 Orange Street,
    Wilmington, DE 19801`,
      phone: "+1 302 440 1477",
      css: "markerUSA",
      country: "United States of America",
      countryImage: data.DelawareImage.childImageSharp.gatsbyImageData,
    },
    {
      city: "South Africa",
      address: (
        <>
          M1 Logistics (Pty) Ltd. <br /> SA Ensafrica Building The Marc,
          <br />
          129 Rivonia Rd, Sandton,
          <br />
          Gauteng-2196
        </>
      ),
      phone: "+27 (0) 74 572 4316",
      css: "markerSouthAfrica",
      country: "South Africa",
      countryImage: data.SouthAfricaImage.childImageSharp.gatsbyImageData,
    },
    {
      city: "India",
      address: "TBD",
      phone: "+971 (0)4 874 6677",
      css: "markerIndia",
      country: "India",
      countryImage: data.IndiaImage.childImageSharp.gatsbyImageData,
    },
  ];

  /*
  useEffect(() =>{
    setIsImageChange(!isImageChange)
    let timer = setTimeout(() => setIsImageChange(false), 200);
      return () => {
        clearTimeout(timer);
      };
    
   
  }, [locationImg])*/

  return (
    <div className={classes.locationsWrapper}>
      {!isMobileView && (
        <>
          <div className={classes.headingCTAWrapper}>
            <HeadingCTA
              subheading={"Locations"}
              title={"Welcome to the world of MOTUS | ONE"}
              center
            />
          </div>
          <div className={classes.mapImgContainer}>
            {!isImageChange ? (
              <div className={[`${classes.mapLocation} ${classes.imgNew}`]}>
                <GatsbyImage image={locationImg} />
              </div>
            ) : (
              <div className={[`${classes.mapLocation} ${classes.imgDefault}`]}>
                <StaticImage
                  src="../../assets/images/locations/locations_dubai.jpg"
                  draggable="false"
                />
              </div>
            )}
          </div>
          <Map
            setLocationImg={(img) => setLocationImg(img)}
            setIsImageChange={(isImageChanges) =>
              setIsImageChange(isImageChanges)
            }
            locations={locationsData}
          />
        </>
      )}

      {isMobileView && (
        <>
          <div div className={classes.mobileHeadingCTAWrapper}>
            <HeadingCTA
              subheading={"Locations"}
              title={"Welcome to the world of MOTUS | ONE"}
              center
            />
          </div>
          <LocationsAccordion />
        </>
      )}
    </div>
  );
};

export default Locations;
