import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Typography from "../../Typography/Typography";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { mobileViewBreakpoint } from "../../helper";

const useStyles = makeStyles((theme) => ({
  locationDetailsWrapper: {
    [theme.breakpoints.down("md")]: {
      padding: "24px 16px",
    },
    opacity: 0,
    visibility: "hidden",
    transform: "translateY(-10px)",
    transition: "all ease 0.2s",
    "& > h2, h4, h5": {
      opacity: 0,
      visibility: "hidden",
      transition: "all ease 0.5s",
    },
  },
  showCard: {
    opacity: 1,
    visibility: "visible",
    transform: "translateY(0px)",
    "& > h2, h4, h5": {
      opacity: 1,
      visibility: "visible",
    },
  },
  root: {
    width: "437px",
    background: "#FFFFFF",
    boxShadow: "0px 2px 20px rgba(0, 0, 0, 0.05)",
    borderRadius: "6px",

    "& .MuiCardContent-root": {
      padding: "32px 32px 24px",

      "& h4": {
        color: "#122044",
        marginBottom: "8px",
      },

      "& h2": {
        color: "#122044",
        marginBottom: "8px",
      },

      "& h5": {
        color: "rgba(0, 0, 0, 0.6)",
      },
    },

    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  title: {
    color: "#122044",
  },
  address: {
    color: "rgba(0, 0, 0, 0.6)",
  },
  footer: {
    background: "rgba(0, 0, 0, 0.02)",
    height: "80px",
    maxHeight: "80px",
    padding: 0,
    paddingRight: "24px",
    justifyContent: "flex-end",
    [theme.breakpoints.down(mobileViewBreakpoint)]: {
      paddingRight: "32px",
    },

    "& a": {
      textDecoration: "none !important",

      "&:first-child": {
        marginRight: "16px",
        [theme.breakpoints.down(mobileViewBreakpoint)]: {
          marginRight: "40px",
        },
      },

      "& p": {
        color: "#4001C5",
      },
    },
  },
}));

const LocationDetails = ({ locationDetails }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));
  const [isLocationChanged, setIsLocationChanged] = useState(false);
  const [locationData, setLocationData] = useState({});
  useEffect(() => {
    setIsLocationChanged(false);
    setLocationData(locationDetails);
    setTimeout(() => {
      setIsLocationChanged(true);
    }, 300);
  }, [locationDetails]);
  return (
    <div
      className={
        isLocationChanged
          ? `${classes.locationDetailsWrapper} ${classes.showCard}`
          : classes.locationDetailsWrapper
      }
    >
      <Card className={classes.root}>
        <CardContent>
          <Typography
            customVariant={(!isMobileView && "h2Bold") || "h4Bold"}
            component="h2"
          >
            {locationData.city}
          </Typography>
          <Typography customVariant="h5Regular">
            {locationData.address}
          </Typography>

          {locationData.multiline && locationData.emails && (
            <>
              <br />
              Connect with us
              <br />
              <br />
              {locationData.emails.map((email) => (
                <>
                  {email.name}{" "}
                  <a href={`mailto:${email.address}`}>
                    <Typography customVariant={"ctaBold"}>
                      {email.address}
                    </Typography>
                  </a>
                  <br />
                </>
              ))}
            </>
          )}
        </CardContent>

        <CardActionArea>
          <CardActions className={classes.footer}>
            {locationData.country !== "South Africa" && (
              <a href={`mailto:sales@motusone.com`}>
                <Typography customVariant={"ctaBold"}>
                  sales@motusone.com
                </Typography>
              </a>
            )}
            <a href={`tel:${locationData.phone}`}>
              <Typography customVariant={"ctaBold"}>
                {locationData.phone}
              </Typography>
            </a>
          </CardActions>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default LocationDetails;
